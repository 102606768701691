import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidCode, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'


export default function ForgotPassword() {

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')


  
  useEffect(()=>{
    if (searchParams.get("code")) {
      setCode(searchParams.get("code")!);
      setUsername(searchParams.get("email")!)
    }
  },[])
  
  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !usernameIsValid ||
    username.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    try {
      console.log(code)
      await authContext.changePassword(username, code, password)
      setReset(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" mt={1} mb={1}>
        <Code codeIsValid={codeIsValid} value={code} setCode={setCode} />
      </Box>
      <Box width="80%" mt={1} mb={1}>
        <Username usernameIsValid={usernameIsValid} value={username} setUsername={setUsername} />
      </Box>
      <Box width="80%" mt={1} mb={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" mt={1} mb={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row">
          <Box mt={1} mb={1}>
            <Button onClick={() => navigate(-1)} color="secondary" variant="squareOutline">
              Cancel
            </Button>
          </Box>
          <Box mt={1} mb={1}>
            <Button sx={{
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0"
              }
            }} disabled={isValid} color="primary" variant="squareBlack" onClick={resetPassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography gutterBottom variant="h5">{`You've sucessfully reset your password!`}</Typography>

{authContext.sessionInfo?.sub ? 
      <Box mt={1}>
        <Button onClick={() => navigate('/')} color="primary" variant="contained">
          Continue
        </Button>
      </Box>
      :
      
      <Box mt={1}>
        <Button onClick={() => navigate('/signin')} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
}
    </>
  )

  return (
    <Grid sx={{ height: "100vh" }} container direction="row" justifyContent="center" alignItems="start">
      <Grid xs={11} sm={6} lg={4} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box >
            <Grid container direction="row" alignItems="center">
              <Typography gutterBottom variant="h3">Reset Password</Typography>
            </Grid>
          </Box>

          {!reset ? updatePassword : passwordReset}
        </Grid>
      </Grid>
    </Grid>
  )
}
