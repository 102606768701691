import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { useAddBusinessMutation } from "../../redux/services/businesses";
import { Business } from "@prisma/client";
import AppleLogin from "react-apple-login"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'
import { Email, Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'
import { signInWithRedirect } from 'aws-amplify/auth';
import { sendEmailBusinessSignup } from '../../libs/email';


const SignUp: React.FunctionComponent<{}> = () => {



  const params = useParams<{ code: string }>();


  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)
  const [addBusiness] = useAddBusinessMutation();
  const uid = function () {
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
  }
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')


  const addNewBusiness = async (data: Business) => {
    try {

      //console.log("Adding Business " + data.id)
      await addBusiness(data).unwrap();
      //navigate(`/business/edit`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }
  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0


  const authContext = useContext(AuthContext)

  const signUpClicked = async () => {
    try {
      await authContext.signOut();
      //console.log("ID = " + authContext.sessionInfo?.sub)
      const res: any = await authContext.signUpWithBusinessEmail(email, email, password)
      //console.log(res)
      //console.log("ID Created")
      setCreated(true)
      //console.log(authContext)
      try {
        let business: Business = {
          email: '',
          id: res,
          password: '',
          name: '',
          stripeid: '',
          stripeverified: 0,
          businessName: '',
          logoUrl: '',
          address: '',
          website: '',
          phone: '',
          joinDate: new Date(),
          bio: '',
          social: ''
        }
        business.email = email

        //console.log(business.id)
        // setCurrentBusiness(business)
        // //console.log(currentBusiness?.email);
        await addNewBusiness(business);
        
      sendEmailBusinessSignup(email);
        localStorage.setItem('email', email)
        navigate('/verify')

      } catch (err) {
        const mayBeError = isErrorWithMessage(err);
        if (mayBeError) {
          setError(err.data.message);
        } else {
          setError("Unknown Error");
        }

      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }


  function handleSignInGoogleClick(customState: string) {
    signInWithRedirect({
      provider: "Google",
      customState
    });
  }
  function handleSignInFacebookClick(customState: string) {
    signInWithRedirect({
      provider: "Facebook",
      customState
    });
  }
  function handleSignInAppleClick(customState: string) {
    signInWithRedirect({
      provider: "Apple",
      customState
    });
  }

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/signin')
  }
  const signUp = (
    <>
      <Box width="100%" mt={1} mb={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="100%"  mt={1} mb={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box mt={1}>
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      </Box>
      <Box mt={1} mb={1}  width="100%">
            <Divider orientation="horizontal" flexItem />
          </Box>
      {/* Buttons */}
      <Box width="100%" pt={0} alignContent="center" justifyContent="center">
        <Grid container direction="row" alignContent="center" justifyContent="center">
          <Box mt={1} mb={1} width="100%" alignContent="center" justifyContent="center">
            <Button disabled={isValid} fullWidth color="primary" variant="squareBlack" onClick={signUpClicked}>
              Sign Up Business
            </Button>
          </Box> <Box mt={1} mb={1}  width="100%">
            <Divider orientation="horizontal" flexItem />
          </Box>
          <Box mt={1} mb={1} width="100%">

            <Button onClick={() => { handleSignInGoogleClick("business") }} sx={{ textTransform: "none" }} startIcon={<GoogleIcon />} fullWidth variant="squareOutline">
              Sign Up with Google
            </Button>
          </Box>
          <Box mt={1} mb={1} width="100%">

            <Button onClick={() => { handleSignInFacebookClick("business") }} sx={{ textTransform: "none" }} startIcon={<FacebookIcon />} fullWidth variant="squareOutline">
              Sign Up with Facebook
            </Button>
          </Box>
          <Box mt={1} mb={1}  width="100%">
            <Divider orientation="horizontal" flexItem />
          </Box>
          <Box mt={1} mb={1} width="100%" justifyContent="center">

            <Typography variant='subtitle1' align="center" justifyContent="center" onClick={signIn} gutterBottom>
              Already have an account? Log in
            </Typography>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const accountCreated = (
    <>
      <Typography align='center' variant="h5">{`Created ${email} account`}</Typography>
      <Typography align='center' variant="h6">{`Verify Code sent to ${email}`}</Typography>

      <Box mt={4} mb={4}>
        <Button onClick={() => navigate('/verify')} color="primary" variant="contained">
          Send Code
        </Button>
      </Box>

    </>
  )

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="center" item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          {/* Title */}
          <Box mt={1} mb={3}>
            <Grid container direction="column" alignItems="center">
              <Typography variant="h1" gutterBottom>Sign Up</Typography>
              <Typography variant="h4">A better way to tip is a few clicks away.</Typography>
            </Grid>
          </Box>

          {signUp}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUp
