import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseQuerry = fetchBaseQuery({
 //baseUrl: "http://127.0.0.1:3010/api",
  baseUrl: "https://tipzserver.com/backend/api",
});

const baseQuerryWithRetry = retry(baseQuerry, { maxRetries: 1 });

export const api = createApi({
  baseQuery: baseQuerryWithRetry,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});

