import http from "../http-common";

export function sendEmailBusinessInvite(businessName: string, userId: string, name: string, email: string) {

    return http.post("/sendinvitebusiness", { businessName, userId, name, email });
}
export function sendEmailEmployeeInvite(employeeName: string, userId: string, name: string, email: string) {

    return http.post("/sendinviteemployee", { employeeName, userId, name, email });
}
export function sendEmailInvite(name: string, email: string) {

    return http.post("/sendinvite", { name, email });
}
export function sendEmailBusinessSignup(email: string) {

    return http.post("/sendsignupbusiness", { email });
} 


export function sendEmailBusinessStop(email: string) {
console.log(email)
    return http.post("/sendstopbusiness", { email });
} 


export function sendEmailEmployeeSignup(email: string) {

    return http.post("/sendsignupemployee", { email });
}

export function sendEmailEmployeeStop(email: string) {

    return http.post("/sendstopemployee", { email });
} 
export function sendEmailEmployeeComplete(email: string) {

    return http.post("/sendcompletedemployee", { email });
}
export function sendEmailBusinessComplete(businessName: string, email: string) {

    return http.post("/sendcompletedbusiness", { businessName, email });
}
export function sendEmailBusinessTip(name: string, businessName: string, email: string, amount: string, paymentid: string, date: string, message: string) {

    return http.post("/tipbusiness", {name, businessName, email, amount, paymentid, date, message });
}


export function sendEmailEmployeeTip(name: string, email: string, amount: string, paymentid: string, date: string, message:string) {

    return http.post("/tipemployee", { name, email, amount, paymentid, date, message });
}


export function sendEmailTip(name: string, email: string, amount: string, paymentid: string, date: string, message:string) {

    return http.post("/senttip", { name, email, amount, paymentid, date, message });
}