import React, { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Business } from "@prisma/client";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Path } from "../../paths";
import {
  useGetBusinessQuery
} from "../../redux/services/businesses";


import { Avatar, ButtonBase } from '@mui/material';
import { useForm } from "react-hook-form";
import { AuthContext } from '../../context/authContext';

const FinalizeBusiness: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  //console.log("Finalize");
  const authContext = useContext(AuthContext)

  const [setup, setSetup] = useState(false);
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetBusinessQuery(authContext.sessionInfo?.sub || "");


  const [searchParams, setSearchParams] = useSearchParams();
  const form = useForm<Business>({

  })


  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
    }

    if (searchParams.get("setup") === "true") {
      setSetup(true);
    }
  });


  const [error, setError] = useState('')




  if (isLoading) {
    return <span>Loading</span>;
  }

  //console.log("Data = " + data);
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const editBusiness = () => {

    navigate(`${Path.businessEdit}/?setup=true`);
  }
  const confirmBusiness = () => {
    if (data?.stripeverified)
      navigate(`${Path.businessDashboard}/`);
    else
      navigate(`${Path.businessStripe}/`);
  }

  const finalize = (
    <>




      {/* Buttons */}
      <Box width="100%" pl={1} pr={1} pb={1} justifyContent="right" mt={0} mb={0} >
        <Grid container alignItems="center" mt={0} mb={0}>

          <Grid sx={{ marginLeft: 'auto' }} mt={0} mb={0}>
            <Button color="primary" type="submit" onClick={editBusiness} variant="roundedBlack">
              <Typography variant="subtitle2">Edit</Typography>
            </Button>
          </Grid>

        </Grid>
      </Box>
      <Box mt={0} width="100%" p={1}>
        <Paper elevation={0} variant="outlined"
          sx={{
            p: 1,
            width: "100%",
            flexGrow: 1,
            borderRadius: "25px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid  container direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap">
            <Grid item >
              <ButtonBase sx={{ width: 110, height: 110 }}>
                <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "90px", width: "90px" }} src={data?.logoUrl} />
              </ButtonBase>
            </Grid>
            <Grid  item container alignItems="center" width="70%" >
              <Grid  item container width="100%" direction="column" alignItems="left" alignContent="left" >
                <Grid item width="100%" >
                  <Typography noWrap align="left" variant="h3" component="div">
                    {data?.businessName}
                  </Typography>
                </Grid>
                <Grid item width="100%" >

                  <Typography sx={{wordWrap: 'break-word'}} variant="subtitle1" gutterBottom>
                    {data?.address}
                  </Typography>
                </Grid><Grid item width="100%" >

                  <Typography noWrap variant="subtitle1" gutterBottom>
                    {data?.phone}
                  </Typography>
                </Grid>
                <Grid item width="100%" >
                  <Typography noWrap variant="subtitle1" sx={{
                    textDecoration: "none"
                  }} color="text.secondary">

                    <Link target="_blank" style={{ color: "#000000" }}
                      to={data ?

                        data.website.includes("http")
                          ?
                          data.website : "https://" + data.website

                        : ""} >
                      {data?.website}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      {(data?.bio !== "" || data.social !== "" || data.name !== "") ?
        <Box width="100%" >

          <Paper elevation={0} variant="outlined"
            sx={{
              borderRadius: "24px",
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
          >

            <Grid item xs={12} sm container>
              <Grid item xs direction="column" p={2} >
                <Typography variant="h5" gutterBottom color="text.secondary">
                  {data?.name}
                </Typography> <Typography gutterBottom variant="body1" color="text.secondary">
                  {data?.bio}
                </Typography>
                {data?.social ?
                  <Typography variant="subtitle1" color="text.secondary">
                    Follow {data?.businessName} at: <Link  target="_blank" to={data ? data.social.includes("http") ? data.social : "https://" + data.social : ""} >{data?.social}</Link>
                  </Typography>
                  : <></>
                }
              </Grid>
            </Grid>
          </Paper>

        </Box>
        :
        <></>
      }
      <Box mt={1} width="100%" p={1} justifyContent="center">
        <Grid container direction="row" justifyContent="center">
          <Box width="100%" justifyContent="center">
            <Button fullWidth color="info" type="submit" onClick={confirmBusiness} variant="roundedPurple">
              <Typography variant="subtitle2">Confirm</Typography>
            </Button>
          </Box>

        </Grid>
      </Box>

    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box >
            <Grid container direction="column" alignItems="center">
              <Typography align="center" variant="h1" >Finalize your</Typography>
              <Typography align="center" variant="h1" >Profile</Typography>
            </Grid>
          </Box>

          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinalizeBusiness
