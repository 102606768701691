import React, { useState, useContext } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from './FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../paths";
import {
  useGetAllTipsBusinessQuery,
} from "../redux/services/tips";

import { Tip } from "@prisma/client"
import { isErrorWithMessage } from "../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../hooks/useAuthHooks'

import { AuthContext } from '../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase, Divider, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { tips } from '../features/tips/slice';
const TipsListBusiness = (businessId: any, stripe: number) => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  //console.log(params.id);
  const { data, isLoading } = useGetAllTipsBusinessQuery(authContext.sessionInfo?.sub || "");







  const [error, setError] = useState('')




  if (isLoading) {
    return <span>Loading</span>;
  }


  const handleInputChange = (e: any) => {

  }
  const inviteClients = () => {

    navigate(`${Path.businessInviteClients}/`);
  }
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const signUp = () => {

    navigate(`${Path.businessEdit}/`);
  }
  const confirmBusiness = () => {

    navigate(`${Path.businessInvite}/`);
  }


  const sum = data?.map((tip: Tip) => tip.amount).reduce((a, b) => a + b, 0)

  return (
    <>

      <Card variant="outlined" color="info" style={{ margin: "0", padding: "0", backgroundColor: "#6750A4" }}>

        <Box p={2}>
          <Typography gutterBottom variant="subtitle1" color="secondary" component="div">
            Total tips received
          </Typography>
          <Typography gutterBottom variant="h2" color="secondary" component="div">
            ${sum}
          </Typography>
        </Box>
      </Card>
      <Box width="100%" mt={1}>
        <Typography gutterBottom variant="body1" color="primary" component="div">
          Latest Tips
        </Typography>
      </Box>

      <Box mt={1} width="100%">

      {!data?.length ?
            stripe !== 1 ?
              <Typography align="center" variant="subtitle1">
                Connect with Stripe to receive your first Tip!
              </Typography>
              :
              <Typography onClick={inviteClients} align="center" variant="subtitle1">
                Share your profile to receive your first Tip!
              </Typography>
            :
        <Paper elevation={0} variant="outlined"
          sx={{
            pt: 2,
            flexGrow: 1,
            borderRadius: "4px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#F5F5F5' : '#F5F5F5',
          }}
        >
          {data?.map((tip: Tip) =>
            

<>

              <Grid key={tip.id} container direction="row">
                <Grid container direction="column" width="75px" textAlign="center" justifyContent="center" alignItems="center">
                  <Grid item textAlign="center" justifyContent="center" alignItems="center">
                    <ButtonBase sx={{ width: 50, height: 50 }}>
                      <Avatar alt={tip.name} src={tip.logourl} />
                    </ButtonBase>
                  </Grid>
                  <Grid item textAlign="center" justifyContent="center" alignItems="center">
                    <Typography align="center" gutterBottom variant="h5" sx={{wordWrap: 'break-word'}}>
                      {tip.recipientName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm container>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography align="left" gutterBottom variant="body1" >
                        {tip.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="left" gutterBottom variant="body1" >
                        {tip.message}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography align="left" variant="h5" gutterBottom>
                        ${tip.amount}
                      </Typography>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={6} alignItems="right" container direction="column">
                  <Box  >

                    <Typography align="right" variant="body1">
                      {new Date(tip.date).toLocaleDateString('default', { day: '2-digit', month: 'long', year: 'numeric' })
                      }
                    </Typography>


                  </Box>
                </Grid>
              </Grid>
              
            </>
          )}
          
        </Paper>
}
        {!data?.length &&
          stripe === 1 ?
          <Grid item mt={1} direction="row" textAlign="center" justifyContent="center" alignItems="center" >
            <Button onClick={inviteClients} variant="roundedPurple">
              Invite Clients
            </Button>
          </Grid>
          : <></>}



      </Box>
    </>
  )
}

export default TipsListBusiness
