import { Tip } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { tipsApi } from "../../redux/services/tips";
import { RootState } from "../../redux/store";

interface InitialState {
  tips: Tip[] | null;
}

const initialState: InitialState = {
  tips: null,
};

const slice = createSlice({
  name: "tips",
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      tipsApi.endpoints.getAllTips.matchFulfilled,
      (state, action) => {
        state.tips = action.payload;
      }
    );
  },
});

export default slice.reducer;
export const tips = (state: RootState) => state.tips;
