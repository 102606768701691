import { signUp, getCurrentUser, confirmResetPassword, fetchAuthSession, resendSignUpCode, confirmSignUp, fetchUserAttributes, type ConfirmSignUpInput, signIn, type SignInInput, autoSignIn, signOut, resetPassword, ResetPasswordOutput, signInWithRedirect, updateUserAttribute, UpdateUserAttributeOutput, deleteUser } from 'aws-amplify/auth';
import { error } from 'console';


//const userPool: CognitoUserPool = new CognitoUserPool(poolData)

//let currentUser: any = currentAuthenticatedUser()

//export async function currentAuthenticatedUser() {

//  try {
//    const { username, userId, signInDetails } = await getCurrentUser();
//    //console.log(`The username: ${username}`);
//    //console.log(`The userId: ${userId}`);
//    //console.log(`The signInDetails: ${signInDetails}`);
//  } catch (err) {
//    //console.log(err);
//  }
//   return currentUser
//}


export async function getSession() {
  try {
    const user = await getCurrentUser();
    //console.log(user)
    const session = (await fetchAuthSession()).tokens ?? {};
    // //console.log("AMPLIFY SESSION "+accessToken)
    return session
  } catch (err) {
    ////console.log(err);
  }
}
export function handleSignInClick(customState: string) {
  signInWithRedirect({
    provider: "Google",
    customState
  });
}
export async function signUpUserWithEmail(username: string, email: string, password: string, nickname: string, name: string) {
  try {
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email,
          nickname,
          name
        },
        autoSignIn: {
          enabled: false
        }
      }
    });
    //console.log(userId);
    return userId;
  } catch (error) {
    //console.log("error signing up = " + error)
    throw error;
  }

}

export async function verifyCode(username: string, confirmationCode: string) {

  try {
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username,
      confirmationCode
    });

  } catch (error) {
    //console.log("error confirming sign up ", error)
    throw error;
  }
}
export async function handleAutoSignIn() {
  try {
    const signInOutput = await autoSignIn();
    // handle sign-in steps
  } catch (error) {
    //console.log(error);
    throw error;
  }
}

export async function signInWithEmail(username: string, password: string) {
  try {
    const { isSignedIn, nextStep } = await signIn({ username, password });

    if(!isSignedIn)
      throw new Error("User not verified")

  } catch (error) {
    console.log(error)
    //console.log("Error signing in " + error);
    throw error
  }


}

export async function signOutUser() {

  try {
    await signOut();
  } catch (error) {
    //console.log('error signing out: ', error);
    throw error;
  }

}

export async function getAttributes() {
  try {
    const userAttributes = await fetchUserAttributes();
    //console.log(userAttributes);
    return userAttributes
  } catch (error) {
    //console.log(error);
    await signOutUser()
  }
}

export async function setAttribute(attributeKey: string, value: string) {

  try {
    const output = await updateUserAttribute({
      userAttribute: {
        attributeKey,
        value
      }
    });
    handleUpdateUserAttributeNextSteps(output);
  } catch (error) {
    //console.log(error);
  }

}

function handleUpdateUserAttributeNextSteps(output: UpdateUserAttributeOutput) {
  const { nextStep } = output;

  switch (nextStep.updateAttributeStep) {
    case 'CONFIRM_ATTRIBUTE_WITH_CODE':
      const codeDeliveryDetails = nextStep.codeDeliveryDetails;
      //console.log(
      //  `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
      //);
      // Collect the confirmation code from the user and pass to confirmUserAttribute.
      break;
    case 'DONE':
      //console.log(`attribute was successfully updated.`);
      break;
  }
}


export async function setProfile(profile: string) {
  await setAttribute("name", profile)
}

export async function setUserType(usertype: string) {
  await setAttribute("name", "")
  await setAttribute("nickname", usertype)
}

export async function resendCode(username: string) {

  try {
    const output = await resendSignUpCode({ username });

  } catch {


  }

}
export async function handleResetPassword(username: string) {
  try {
    console.log("User="+username);
    const output = await resetPassword({ username });
    handleResetPasswordNextSteps(output);
  } catch (error) {
    throw(error)
  }
}

function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
  const { nextStep } = output;
  switch (nextStep.resetPasswordStep) {
    case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
      const codeDeliveryDetails = nextStep.codeDeliveryDetails;
      //console.log(
      //  `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
      //);
      // Collect the confirmation code from the user and pass to confirmResetPassword.
      break;
    case 'DONE':
      //console.log('Successfully reset password.');
      break;
  }
}
export async function ResetPassword(username: string, confirmationCode: string, newPassword: string) {
  try {
    const output = confirmResetPassword({ username, confirmationCode, newPassword })

  } catch (error) {
    console.log(error)
  }
}
export async function tryDeleteUser() {
  try {
    console.log("Deleting");
    const output = await deleteUser();
    
    console.log(output);
  } catch (error) {
    console.log(error)
  }
}
export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    //  currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
    //   if (err) {
    //      reject(err)
    //    } else {
    //      resolve(res)
    //    }
    //  })
  })
}
