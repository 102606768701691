import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";

import {
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import EmployeeList from '../../components/EmployeeList';
import { Avatar, Divider } from '@mui/material';

const ViewBusiness: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

const [disable, setDisable] = useState(false)
  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetBusinessQuery(params.id || authContext.sessionInfo?.sub || "");
  useEffect(() => {
    if (!params.id) {
      if(authContext.sessionInfo?.identities||authContext.sessionInfo?.identities!=""){
        setReset(true);
      }
      if ((authContext.sessionInfo?.profileComplete === "false" || authContext.sessionInfo?.profileComplete === "") && authContext.sessionInfo?.usertype === "business") {
        navigate(`${Path.businessEdit}/`);
        return;

      } else if ((authContext.sessionInfo?.profileComplete === "false" || authContext.sessionInfo?.profileComplete === "") && authContext.sessionInfo?.usertype === "employee") {

        navigate(`${Path.employeeEdit}/`);
        return;
      }
    }
  }, [params, authContext]);

  const [error, setError] = useState('')

  const [shrink, setShrink] = useState(false);

  const [reset, setReset] = useState(false)


  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    navigate(`${Path.businessEdit}/`);
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const signUp = () => {
    navigate(`${Path.employeeSignup}/${params.id}`);
  }
  const confirmBusiness = () => {

    navigate(`${Path.businessInvite}/`);
  }
  const resetPassword = async () => {
    try {

      //await authContext.changePassword(data?.email)

      navigate(`${Path.reset}/?email=`+data?.email);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const finalize = (
    <>



      {/* Buttons */}
      <Box p={1} width="100%" >

        <Grid container textAlign="center" justifyContent="center" direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
          <Grid item textAlign="center" justifyContent="center">
            <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.logoUrl} />

          </Grid>
        </Grid>



      </Box>
      {params.id ?
        <Box p={1} width="100%" textAlign='center' justifyContent="center">

          <Link to={data?.stripeverified ? '/business/tip/' + data?.id : "#"}>
            {data?.stripeverified ?
              <Button color="info" fullWidth variant="roundedPurple">Tip the whole business</Button>
              :
              <Button color="info" fullWidth variant="squarePurpleOutline">Not Verified</Button>
            }
          </Link>
        </Box>
        :
        <Box></Box>
      }
      <Box p={1} width="100%">
        <Typography gutterBottom variant="h3" component="div">
          {data?.businessName}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {data?.address}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          <Link style={{ color: "#000000" }} target="_blank" to={data ? data.website.includes("http") ? data.website : "https://" + data.website : ""} >{data?.website}</Link>
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {data?.phone}
        </Typography>
        {(data?.name !== "" ?
          <Typography gutterBottom variant="h5" component="div">
            {data?.name}
          </Typography>
          :

          <></>)}

        {(data?.bio !== "" ?
          <Typography gutterBottom variant="subtitle1" component="div">
            {data?.bio}
          </Typography>
          :

          <></>)}
        {(data?.social !== "" ?
          <Typography gutterBottom variant="subtitle1" component="div">
            Follow {data?.businessName} at: <Link to={data ? data.social.includes("http") ? data.social : "https://" + data.social : ""} >{data?.social}</Link>
          </Typography>
          :

          <></>)}



      </Box>

      <Box width="100%" pb={1}>
        {params.id ?
          <Grid container direction="row" alignItems="baseline" spacing={2}  >
            <Grid item>
              <Typography gutterBottom variant="subtitle1" component="div">
                Do you work here?
              </Typography>
            </Grid>
            <Grid item>
              <Button color="secondary" type="submit" onClick={signUp} variant="roundedOutline">
                Sign up
              </Button>
            </Grid>
          </Grid>
          :
          <Grid container direction="column" justifyContent="center">
            <Grid item >
              <Button fullWidth color="secondary" type="submit" onClick={onEdit} variant="roundedBlack">
                Edit
              </Button>
            </Grid>
            {reset?
            <></>
            :
            
            <Grid item mt={1}>
              <Button fullWidth color="primary" type="submit" onClick={resetPassword} variant="roundedBlack">
                Reset Password
              </Button>
            </Grid>
}
          </Grid>


        }
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Box width="100%" p={1}>
        <EmployeeList />
      </Box>
    </>
  )


  return (

    <Grid container direction="row" justifyContent="center" alignItems="center">

      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">


          {params.id ?
            <Divider orientation="horizontal" flexItem /> :
            <Box  >
              <Grid container direction="column" alignItems="center">
                <Typography variant="h1" >Profile</Typography>
              </Grid>
              <Grid mt={1} width="100%" item justifyContent="center">


              </Grid>
            </Box>

          }
          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewBusiness
