import { Business } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { businessesApi } from "../../redux/services/businesses";
import { RootState } from "../../redux/store";

interface InitialState {
  businesses: Business[] | null;
}

const initialState: InitialState = {
  businesses: null,
};

const slice = createSlice({
  name: "businesses",
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      businessesApi.endpoints.getAllBusinesses.matchFulfilled,
      (state, action) => {
        state.businesses = action.payload;
      }
    );
  },
});

export default slice.reducer;
export const selectBusinesses = (state: RootState) => state.businesses;
