import axios from "axios";

export default axios.create({
    
   //baseURL: "http://127.0.0.1:3010/api/",
    baseURL: "https://tipzserver.com/backend/api/",
    headers: {
        "Content-type": "application/json"

    }
});