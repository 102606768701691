export const Path = {
  home: "https://tipzserver.com",
  businessAdd: "/employee/add",
  businessEdit: "/business/edit",
  businessFinalize: "/business/finalize",
  businessView: "/business/view",
  businessViewProfile: "/business/viewprofile",
  businessDashboard: "/business/dashboard",
  businessStripe: "/business/stripe",
  businessInvite: "/business/invite",
  businessLanding: "/business/landing",
  businessInviteClients: "/business/inviteclients",
  standardInvite: "/invite",
  searchBusiness: "/searchBusiness",
  businessQR: "/business/qr",
  businessFinish: "/business/finish",
  employeeEdit: "/employee/edit",
  employeeFinalize: "/employee/finalize",
  employeeSignup: "/signupemployee",
  employeeView: "/employee/view",
  employeeViewProfile: "/employee/viewprofile",
  employeeDashboard: "/employee/dashboard",
  employeeInvite: "/employee/invite",
  employeeLanding: "/employee/landing",
  employeeQR: "/employee/qr",
  employeeStripe: "/employee/stripe",
  employeeFinish: "/employee/finish",
  businessSearch: "/employee/search",
  employee: "/employee",
  status: "/status",
  faq: "/faq",
  login: "/login",
  reset: "/requestcode",
  register: "/register",
  subtotal: "/subtotal",
  checkout: "/checkout",
  thankyou: "/thankyou",
} as const;
