import { Business, Employee, Tip } from '@prisma/client';
import { Hub } from 'aws-amplify/utils';
import { config } from 'process';
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';

import { useAddEmployeeMutation, useGetEmployeeQuery } from "../redux/services/employees";

import { useAddBusinessMutation, useGetBusinessQuery } from "../redux/services/businesses";

import * as cognito from '../libs/cognito'
import { isErrorWithMessage } from '../utils/isErrorWithMessage';
import { getCurrentUser } from 'aws-amplify/auth';
import { sendEmailBusinessSignup, sendEmailEmployeeSignup } from '../libs/email';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: { username?: string; updating?: boolean; email?: string; sub?: string; accessToken?: string; refreshToken?: string; usertype?: string; profileComplete?: string; identities?: string }
  profileComplete?: boolean
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signInWithRedirect?: any
  signUpWithBusinessEmail?: any
  signUpWithAdminEmail?: any
  signUpWithEmployeeEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  resendCode?: any
  forgotPassword?: any
  changePassword?: any
  deleteUser?: any
  getAttributes?: any
  setAttribute?: any
  setProfile?: any
  setProfileFinished?: any
  SetUserType?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  profileComplete: false
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)
  ////console.log("SIGNED IN " + authStatus)
  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}


export const AuthIsBusiness = ({ children }: Props) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)

  //console.log("BUSINESS " + sessionInfo?.usertype)

  return <>{sessionInfo?.usertype === "business" ? children : null}</>
}
export const AuthIsEmployee = ({ children }: Props) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)

  return <>{sessionInfo?.usertype === "employee" ? children : null}</>
}


export const AuthIsAdmin = ({ children }: Props) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)

  return <>{sessionInfo?.usertype === "admin" ? children : null}</>
}


export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [test, setTest] = useState(true)
  const [error, setError] = useState('')
  const [addBusiness] = useAddBusinessMutation();
  const [addEmployee] = useAddEmployeeMutation();
  //const getBusiness] = useGetBusinessQuery();
  const { data, isLoading } = useGetEmployeeQuery("");
  const [profileComplete, setProfileComplete] = useState(false);
  const addNewBusiness = async (data: Business) => {
    try {

      //console.log("Adding Business " + data.id)
      //console.log(data);
      console.log("Adding Business")
      await addBusiness(data).unwrap();
      sendEmailBusinessSignup(data.email)
      //navigate(`/business/edit`);
    } catch (err: any) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }
  const addNewEmployee = async (data: Employee) => {
    try {
      console.log("Adding Employee")
      console.log(data);
      await addEmployee(data).unwrap();
      sendEmailEmployeeSignup(data.email)
      //navigate(`/business/edit`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }


  useEffect(() => {

    if (window.location.href.includes("signout")) {
      signOut()
      return
    }
    if (window.location.href.includes("?code")) {
      //console.log("CODE!!!")
      setSessionInfo({
        usertype: "business",
      })
    }
    async function getSessionInfo() {
      try {
        console.log("Getting Session")
        const session: any = await getSession()
        ////console.log("SESSION DETAILS + " + session)

        //console.log(`${session.accessToken}`)
        setSessionInfo({
          accessToken: session.accessToken.payload,
          //refreshToken: session.refreshToken.payload,
        })

        //window.localStorage.setItem('accessToken', `${session.accessToken.payload}`)

        const attr: any = await getAttributes()
        //console.log("ajflkjfl" + attr)
        //console.log(attr)
console.log(attr.name);
        !attr.name || attr.name === "" || attr.name === "false" ? setProfileComplete(false) : setProfileComplete(true)
        setSessionInfo({
          sub: attr.sub,
          usertype: attr.nickname || "",
          profileComplete: attr.name || "",
          identities: attr.identities || ""
        })


        setAttrInfo(attr)
        setAuthStatus(AuthStatus.SignedIn)
        //console.log("Auth Status = " + authStatus)
      } catch (err) {
        ////console.log("ERROR " + err)
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  const GetBusiness = async (userId: string) => {
    console.log(userId);
    console.log("Test=" + useGetBusinessQuery(userId))

  }

  const GetEmployee = async (userId: string) => {
    let user = useGetEmployeeQuery(userId)
    console.log(user)

  }

  useEffect(() => {
    Hub.listen("auth", async ({ payload }) => {

      async function getSessionInfo() {
        try {
          console.log("Getting Session2")
          const session: any = await getSession()
          //console.log(session)

          //console.log(`${session.accessToken}`)
          setSessionInfo({
            accessToken: session.accessToken.payload,
            //refreshToken: session.refreshToken.payload,
          })

          window.localStorage.setItem('accessToken', `${session.accessToken.payload}`)

          const attr: any = await getAttributes()
          //console.log("ajflkjfl" + attr)
          console.log(attr)

          setSessionInfo({
            sub: attr.sub,
            usertype: attr.nickname || "",
            profileComplete: attr.name || "",
            identities: attr.identities || ""
          })


          setAttrInfo(attr)
          setAuthStatus(AuthStatus.SignedIn)
          //console.log("Auth Status = " + authStatus)
        } catch (err) {
          //console.log("ERROR " + err)
          setAuthStatus(AuthStatus.SignedOut)
        }
      }
      switch (payload.event) {
        case "customOAuthState":

          const attr: any = await getAttributes()
          const email = attr.email;
          console.log(attr);
          console.log("HUB " + email)
          console.log("Custom State = " + payload.data)
          if (!attr.nickname) {
            switch (payload.data) {

              case "business":
                getSessionInfo()


                await SetUserType(payload.data, attr.sub)
                console.log(attr.sub);
                const user = await getCurrentUser();
                console.log(user);
                // GetBusiness(user.userId);
                // GetEmployee(user.userId);

                console.log(user);
                if (user) {
                  //console.log("HUB = " + attr.sub);
                  //console.log(user);
                  //console.log("HUB EMAIL" + email)
                  let business: Business = {
                    email: email,
                    id: attr.sub,
                    password: '',
                    name: '',
                    stripeid: '',
                    stripeverified: 0,
                    businessName: '',
                    logoUrl: '',
                    address: '',
                    website: '',
                    phone: '',
                    joinDate: new Date(),
                    bio: '',
                    social: ''
                  }
                  addNewBusiness(business)
                }


                await SetUserType("business", user.userId);
                break;

              case "employee":
                await SetUserType(payload.data, attr.sub)
                const user2 = await getCurrentUser();
                console.log(user2);
                const busid =
                  localStorage.getItem('businessid');
                console.log(attr.sub);

                // GetBusiness(user2.userId);
                // GetEmployee(user2.userId);
                if (user2) {
                  //console.log("HUB = " + user2.username);
                  //console.log(user2.userId);
                  let employee: Employee = {
                    email: email,
                    id: user2.userId,
                    password: '',
                    name: '',
                    photoUrl: '',
                    joinDate: new Date(),
                    stripeid: '',
                    stripeverified: 0,
                    title: '',
                    userId: busid!,
                    bio: '',
                    social: ''
                  }
                  addNewEmployee(employee)
                }
                await SetUserType("employee", user2.userId);
                break;
            }
          }
          break;

        case "signInWithRedirect":

          console.log("Sign In A")
          //console.log("signInWithRedirect")

          break;
        case "signInWithRedirect_failure":
          console.log("Sign In B")
          //console.log("signInWithRedirect_failure")
          // handle sign in failure
          break;
        case "customOAuthState":
          console.log("Sign In C")
          //console.log("customOAuthState")
          const state = payload.data; // this will be customState provided on signInWithRedirect function
          //console.log(state);
          break;
      }
    });
  }, []);


  ////console.log("AUTH STATUS = " + authStatus)
  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithRedirect() {
    setAuthStatus(AuthStatus.SignedIn)

  }


  async function signInWithEmail(username: string, password: string) {
    try {
      ////console.log("SIGNING IN " + username + " " + password)
      await cognito.signInWithEmail(username, password)

      ////console.log("SIGNED IN")
      setAuthStatus(AuthStatus.SignedIn)
      ////console.log(AuthStatus.SignedIn)
    } catch (err) {
      ////console.log("err")
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }
  //const clientId = "879387739752-bqb8ghakcd0jeua6agiae4he36ts0nk9.apps.googleusercontent.com"

  async function setProfile(name: string) {
    try {

      await cognito.setProfile(name)
    } catch (err) {
      throw err
    }
  }

  async function SetUserType(usertype: string, sub: string) {
    try {
      setSessionInfo({
        sub: sub,
        usertype: usertype,
        profileComplete: ""
      })
      await cognito.setUserType(usertype)
    } catch (err) {

    }
  }
  async function stopUpdating() {
    setSessionInfo({
      updating: false,
    })
  }

  async function setProfileFinished(name: string) {
    //console.log("Set Profile Finished")

    setProfileComplete(true);
  }
  async function signUpWithEmployeeEmail(username: string, email: string, password: string) {
    try {
      const res: any = await cognito.signUpUserWithEmail(username, email, password, "employee", "false")


      setSessionInfo({
        sub: res,
        usertype: "employee"
      })
      return res;
    } catch (err) {
      throw err
    }
  }
  async function signUpWithBusinessEmail(username: string, email: string, password: string) {
    try {
      const res: any = await cognito.signUpUserWithEmail(username, email, password, "business", "false")
      ////console.log("USER = " + res['userSub'])

      setSessionInfo({
        sub: res,
        usertype: "business"
      })
      return res;


    } catch (err) {
      throw err
    }
  }


  async function signUpWithAdminEmail(username: string, email: string, password: string) {
    try {
      const res: any = await cognito.signUpUserWithEmail(username, email, password, "business", "false")
      ////console.log("USER = " + res['userSub'])


      return res;


    } catch (err) {
      throw err
    }
  }


  function parseURL() {

  }
  async function signOut() {
    await cognito.signOutUser()

    setSessionInfo({
      usertype: ""
    })
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function signInWithGoogle() {
    try {
      await cognito.handleSignInClick("")
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr.key, attr.value)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.handleResetPassword(username)
    } catch (err) {
      throw err
    }
  }
  async function resendCode(username: string) {
    try {
      await cognito.resendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string) {
    try {
      await cognito.handleResetPassword(username)
    } catch (err) {
      throw err
    }
  }
  async function deleteUser() {
    try {
      await cognito.tryDeleteUser();
    } catch (err) {
      console.log("Error " + err)
      throw err
    }
  }

  async function changePassword(user: string, code: string, newPassword: string) {
    try {
      console.log()
      await cognito.ResetPassword(user, code, newPassword);
    } catch (err) {
      throw err
    }
  }

  async function changePassword2(oldPassword: string, newPassword: string) {
    try {
      console.log()
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    profileComplete,
    attrInfo,
    signUpWithBusinessEmail,
    signUpWithAdminEmail,
    signUpWithEmployeeEmail,
    signInWithEmail,
    signInWithRedirect,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    resendCode,
    forgotPassword,
    changePassword,
    deleteUser,
    getAttributes,
    setAttribute,
    setProfile,
    setProfileFinished,
    SetUserType
  }
  ////console.log(children)
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
