import React, { useState, useContext, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import {GoogleLogin} from 'react-google-login'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'


const SignOut: React.FunctionComponent<{}> = () => {

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')

 //console.log("SIGN OUT")
  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
const signout = async()=>{
  await authContext.signOut();
  
  navigate('/')
}
signout();
return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
    </Grid>
  )
}

export default SignOut
