import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import businesses from "../features/businesses/slice";
import employees from "../features/employees/slice";
import tips from "../features/tips/slice";
import { api } from "./services/api";
//import { listenerMiddleware } from "../middleware/auth";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    businesses,
    employees,
    tips,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware()
    .concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
