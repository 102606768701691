import { useState, FormEvent, ChangeEvent } from 'react';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Typography } from '@mui/material';

const defaultFormFields = {
  displayName: '',
  email: ''
}

const PaymentForm = (params: any) => {
  const elements = useElements();
  const stripe = useStripe();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [formFields, setFormFields] = useState(defaultFormFields);

  const { displayName, email } = formFields;

  const paymentHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessingPayment(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://tipzserver.com/thankyou/',
        payment_method_data: {
          billing_details: {
            name: params.name,
            email: params.email,
          }
        }
      },
    });

    setIsProcessingPayment(false);

    if (error) {
      setErrorMessage(error.message);
    } else {
      // Handle successful payment
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  }

  return (
    <div className='mt-3'>
      <form onSubmit={paymentHandler}>
        <PaymentElement />
        {errorMessage &&
          <div className='text-pink-500 p-2 rounded-md mt-2 bold bg-pink-100'>
            {errorMessage}
          </div>}
        <Box width="100%" mt={3} mb={1}>
          <Button fullWidth type="submit" variant="roundedPurple">
            <Typography variant="subtitle2">Pay</Typography>
          </Button>
        </Box>
      </form>
    </div>
  )
};

export default PaymentForm;
