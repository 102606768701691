import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../paths";
import {
  useGetAllEmployeesBusinessQuery,
} from "../redux/services/employees";

import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../hooks/useAuthHooks'

import { AuthContext } from '../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase, InputAdornment, Stack, Pagination } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
const EmployeeList = (businessId: any) => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  //console.log(params.id);
  //console.log(authContext.sessionInfo?.sub)
  const { data, isLoading } = useGetAllEmployeesBusinessQuery(params.id || authContext.sessionInfo?.sub || "");



  const [filteredEmployees, setFilteredEmployees] = useState(data);




  const [error, setError] = useState('')


  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (array: Employee[], pageSize: number) => {
    const pageCount = Math.ceil(array.length / pageSize);
    return Array.from({ length: pageCount }, (_, index) =>
      array.slice(index * pageSize, (index + 1) * pageSize)
    );
  };

  const [shrink, setShrink] = useState(false);
  const pageSize = 5;

  const paginatedPosts = paginate(filteredEmployees || [], pageSize);
  const currentPosts = paginatedPosts[currentPage - 1];
  useEffect(()=>{

    if (data) {

      const filtered = data?.filter((employee) => employee.stripeverified === 1);
      
    console.log("Filter = "+filtered?.length);
      setFilteredEmployees(filtered)
  
    }
  },[data ])



  const handleInputChange = (e: any) => {
    const filtered = data?.filter((employee) => (employee.name.toLowerCase().includes(e.target.value.toLowerCase())));
    const filtered2 = filtered?.filter((employee)=>employee.stripeverified === 1)
    setFilteredEmployees(filtered2);
  }

  if (isLoading) {
    return <span>Loading</span>;
  }
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const signUp = () => {

    navigate(`${Path.businessEdit}/`);
  }
  const confirmBusiness = () => {

    navigate(`${Path.businessInvite}/`);
  }

  return (
    <>

      <Box width="100%" >
        <TextField
          onChange={handleInputChange}
          variant='filled'

          onFocus={() => setShrink(true)}
          onBlur={(e) => setShrink(!!e.target.value)}
          InputLabelProps={{
            shrink,
            sx: { left: "35px" }
          }}
          sx={(theme) => ({
            '& .MuiInputAdornment-root': {
              p: 0,
              mb: 2,
              ml: 0
            },
            '& .MuiOutlinedInput-notchedOutline': {
              px: 25.5,
            },
          })}
          InputProps={{



            sx: { borderRadius: 25, borderStyle: "none", elevation: 0, borderBottom: 0 },
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          type="text"
          name="Search"
          id="search"
          label={'Search by employee'} />

      </Box>

      {currentPosts && currentPosts?.map((employee: Employee) =>
        <>

          <Box mt={2} width="100%">

            <Link to={employee?.stripeverified ? '/employee/tip/' + employee?.id : "#"}>
              <Paper elevation={0} variant="outlined"
                sx={{
                  p: 1,
                  width: "100%",
                  flexGrow: 1,
                  borderRadius: "25px",
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid xs={12} sm={12} container direction="row" width="100%" alignItems="center" >
                  <Grid xs={3} sm={3} p={1} item>
                    <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "90px", width: "90px" }} src={employee?.photoUrl} />

                  </Grid>

                  <Grid item xs={9} sm={9} pr={2} alignItems="right" alignContent="right" justifyContent="right" justifyItems="right">
                    <Grid container direction="column" alignItems="right" alignContent="right" justifyContent="flex-end" justifyItems="right">
                      <Grid item p={1}>
                        <Typography align="right" variant="h5" >
                          {employee?.name}
                        </Typography>
                      </Grid>
                      <Grid item alignItems="flex-end" alignContent="flex-end" justifyContent="flex-end" justifyItems="flex-end">
                        <Stack justifyContent="flex-end" alignItems="flex-end">
                          <Link to={employee?.stripeverified ? '/employee/tip/' + employee?.id : "#"}>
                            {employee?.stripeverified ?
                              <Button variant="roundedPurple">Tip {employee?.name}</Button>
                              :
                              <Button variant="roundedGrey">Not Verified</Button>
                            }
                          </Link>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Link>




          </Box>
        </>
      )}

      {paginatedPosts && paginatedPosts.length > 1 && (
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            size="large"
            siblingCount={1}
            boundaryCount={1}
            count={paginatedPosts.length}
            page={currentPage}
            onChange={(_, newPage) => setCurrentPage(newPage)}
          />
        </Box>
      )}
    </>
  )
}

export default EmployeeList
