import React, { useState, useContext, useEffect } from 'react'
import http from "../../http-common";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { sendEmailEmployeeComplete } from '../../libs/email';
import { Path } from "../../paths";
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Email } from '../../components/authComponents'
import { sendEmailEmployeeStop } from '../../libs/email'

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
const StripeEmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const { email, setEmail, emailIsValid } = useValidEmail('')
  const params = useParams<{ stripe: string }>();
  const authContext = useContext(AuthContext)
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");


  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
    }
  });

  const [editEmployee] = useEditEmployeeMutation();

  const [verified, setVerified] = useState('');
  const form = useForm<Employee>({

  })

  type Invite = {
    name: string,
    email: string
  }

  const { register, handleSubmit, watch } = form;
  const [error, setError] = useState('')

  const [emailSent, setEmailSent] = useState(false);
  const [verify, setVerify] = useState(false)

  const [connecting, setConnecting] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const stripeVerified = async (stripeID: string) => {
    if (!authContext.sessionInfo?.sub)
      return;
    if (!data)
      return
    try {


      let employee: Employee = {
        email: data.email,
        id: data.id,
        password: data.password,
        name: data.name,
        photoUrl: data.photoUrl,
        joinDate: data.joinDate,
        stripeid: data.stripeid,
        stripeverified: 1,
        title: data.title,
        userId: data.userId,
        bio: data.bio,
        social: data.social
      }
      //console.log("try1")


      //console.log("try2")
      //console.log("sadfafdds")
      await editEmployee(employee).unwrap();
      sendEmailEmployeeStop(employee.email);
      //    navigate(`${Path.employeeFinalize}`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };


  const verifyStripe = async () => {
    //console.log(data?.stripeverified);
    console.log("verify stripe");
    if (!data || data.stripeid === "" || data.stripeverified === 1 && verified === "true")
      return;
    //console.log("Verify " + data.stripeid)

    const response = await fetch("https://tipzserver.com/backend/api/employees/verified", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/verified", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        stripeid: data.stripeid,
      })
    })
    const dataRes = await response.json();
    //console.log(dataRes)
    //console.log(dataRes.charges_enabled)
    if (dataRes.details_submitted === true) {
      setVerified("true")
      await stripeVerified(data.stripeid);
      if (searchParams.get("stripe") === "true") {
        if (!emailSent) {
          console.log("send email!");
          setEmailSent(true);
          sendEmailEmployeeComplete(data?.email || "");
          navigate(`${Path.employeeFinish}`);
        }

      }
    }
  }

  useEffect(() => {

    console.log("Stripe Verified = " + data?.stripeverified)
    if (data?.stripeverified === 0) {
      verifyStripe();
      setVerify(true);
    }



  }, [data]);


  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    stripeLogin();
    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  //console.log(params.stripe);

  const editStripe = async () => {
    //console.log("Editing");
  }








  const connectStripe = async () => {

    if (connecting)
      return
    setConnecting(true);
    const response = await fetch("https://tipzserver.com/backend/api/employees/createstripe", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/createstripe", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: data?.email,
        id: data?.id,
      })
    })

    const dataRes = await response.json();
    if (dataRes.message === "An existing linked Stripe account is not yet completed") {
      setError("An existing linked Stripe account is not yet completed. Please complete the existing account before creating a new one.");
      setConnecting(false);
      return;
    }
    //console.log(dataRes.url)
    let splitString = dataRes.url.split("/")
    //console.log(splitString[splitString.length - 2])
    handleEditEmployee(splitString[splitString.length - 2])
    window.location.href = dataRes.url
  };


  const stripeLogin = async () => {
    //console.log(data?.stripeid)
    if (!data || data.stripeid === "")
      return;

    const response = await fetch("https://tipzserver.com/backend/api/employees/stripelogin", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/verified", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        stripeid: data.stripeid,
      })
    })

    const dataRes = await response.json();
    //console.log(dataRes)
    window.open(dataRes.url, "_blank")

  }
  const handleEditEmployee = async (stripeID: string) => {
    if (!authContext.sessionInfo?.sub)
      return;
    if (!data)
      return
    try {


      let employee: Employee = {
        email: data.email,
        id: data.id,
        password: data.password,
        name: data.name,
        photoUrl: data.photoUrl,
        joinDate: data.joinDate,
        stripeid: stripeID,
        stripeverified: data.stripeverified,
        title: data.title,
        userId: data.userId,
        bio: data.bio,
        social: data.social
      }
      //console.log("try1")


      //console.log("try2")
      //console.log("sadfafdds")
      await editEmployee(employee).unwrap();
      //    navigate(`${Path.employeeFinalize}`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };



  const viewProfile = () => {

    navigate(`${Path.employeeView}/`);
  }

  const finalize = (
    <>


      <Box width="100%" justifyContent="center">
        <Grid container direction="row" alignItems="center" justifyItems="center" justifyContent="center">
          <Box width="100%" justifyItems="center" alignItems="center" justifyContent="center" textAlign='center'>
            <Box>
              <Grid container direction="column" alignItems="center">
                <Typography variant="h1" gutterBottom>Get Paid</Typography>
              </Grid>
            </Box>
            {
              (data && data.stripeverified === 1) || verified === "true" ?

                <Button disabled={true} sx={{
                  "&.Mui-disabled": {
                    background: "#ECECEC",
                    color: "#a6a6a6"
                  }
                }} color="info" onClick={editStripe} variant="roundedPurple">
                  <TaskAltOutlinedIcon />  <Typography ml={1} variant="subtitle2">
                    Stripe Connected</Typography>
                </Button>
                :
                connecting ? <Button color="info" disabled variant="roundedPurple">Connecting  </Button>
                  :
                  <Button color="info" onClick={connectStripe} variant="roundedPurple">Connect with Stripe</Button>


            }
          </Box>

        </Grid>

        <Grid container direction="row" alignItems="top" justifyContent="center">
          <Box mt={2} mb={1} width="100%" justifyContent="center" textAlign='center'>

            {
              (data && data.stripeverified === 1) || verified === "true" ?

                <Button disabled={false} sx={{
                  minWidth: "185px",
                  "&.Mui-disabled": {
                    background: "#ECECEC",
                    color: "#a6a6a6"
                  }
                }} color="info" onClick={onEdit} variant="roundedPurple">
                  <Typography ml={1} variant="subtitle2">
                    Open Stripe</Typography>
                </Button>
                :
                <></>
            }
          </Box>

        </Grid>
      </Box >

    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" justifyContent="center" item>
        <Grid container direction="column" justifyContent="center" alignItems="center">


          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StripeEmployee
