import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useSearchParams } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import { Path } from "./paths";

import "./App.scss";
import SubTotalCard from "./components/SubtotalCard";
import Checkout from "./pages/checkout";
import Thankyou from "./pages/thankyou";
import Hello from './routes/auth/editBusiness'
import Header from './routes/auth/header'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn, AuthContext, AuthIsBusiness, AuthIsEmployee, AuthIsAdmin } from './context/authContext'

import SignIn from './routes/auth/signIn'
import SignUp from './routes/auth/signUp'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import TipEmployee from "./routes/auth/tipEmployee";

import { createTheme, ThemeProvider } from "@mui/material";
import Home from "./pages/home";
import Landing from "./pages/landing";
import EditBusiness from "./routes/auth/editBusiness";
import ViewBusiness from "./routes/auth/viewBusiness";
import QRBusiness from "./routes/auth/qrBusiness";
import InviteBusiness from "./routes/auth/inviteBusiness";
import FinalizeBusiness from "./routes/auth/finalizeBusiness";
import FinishBusiness from "./routes/auth/finishBusiness";
import SearchBusiness from "./routes/auth/searchBusiness";


import EditEmployee from "./routes/auth/editEmployee";
import QREmployee from "./routes/auth/qrEmployee";
import InviteEmployee from "./routes/auth/inviteEmployee";
import FinalizeEmployee from "./routes/auth/finalizeEmployee";
import FinishEmployee from "./routes/auth/finishEmployee";
import SignUpEmployee from "./routes/auth/signUpEmployee";
import StripeEmployee from "./routes/auth/stripeEmployee";
import ViewEmployee from "./routes/auth/viewEmployee";
import TipCheckout from "./routes/auth/tipCheckout";
import TipBusiness from "./routes/auth/tipBusiness";
import StripeBusiness from "./routes/auth/stripeBusiness";
import InviteFriends from "./routes/auth/inviteFriends";
import Landing2 from "./pages/landing2";
import DashboardBusiness from "./routes/auth/dashboardBusiness";
import DashboardEmployee from "./routes/auth/dashboardEmployee";
import { bool } from "aws-sdk/clients/signer";
import SearchBusinessEmployee from "./routes/auth/searchBusinessEmployee";
import SignOut from "./routes/auth/signout";
import ConfigureAmplifyClientSide from './configureAmplify';
import Redirect from "./routes/auth/redirect";
import InviteBusinessClients from "./routes/auth/inviteBusinessClients";
import Footer from "./routes/auth/footer";
import SearchBusinessQR from "./routes/auth/searchBusinessQR";
import VerifyEmployeeCode from "./routes/auth/verifyEmployee";
import SetupBusiness from "./routes/auth/setupBusiness";
import FAQ from "./pages/faq";


declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    squarePurple: true;
    squareBlack: true;
    squareBlackFixed: true;
    squareOutline: true;
    squarePurpleOutline: true;
    roundedBlack: true;
    roundedPurple: true;
    roundedPurpleLight: true;
    roundedGrey: true;
    roundedOutline: true;

  }
}


let lightTheme = createTheme({

  typography: {
    // logo: {
    //   fontFamily: [
    //     'Space Grotesk',
    //     'cursive',
    //   ].join(','),
    //   fontWeight: 700,
    //   fontSize: 44,
    //  },
    h1: {
      fontWeight: 700,
      fontSize: 36,
    },

    h2: {
      fontWeight: 600,
      fontSize: 30,

    },
    h3: {
      fontWeight: 600,
      fontSize: 24,

    },
    h4: {
      fontWeight: 400,
      fontSize: 18,
    },
    h5: {
      fontWeight: 600,
      fontSize: 14,

    },
    h6: {
      fontFamily: [
        'Space Grotesk',
        'cursive',
      ].join(','),
      fontWeight: 700,
      fontSize: 24,

    },
    subtitle1: {

      fontWeight: 400,
      fontSize: 14,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
    },
    body1: {

      fontWeight: 400,
      fontSize: 12,
    },
    body2: {

      fontWeight: 400,
      fontSize: 8,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: "break-word"
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "auto",
          margin: 10,

        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {

            borderRadius: "0",
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 1)",
              borderRadius: "0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 0, 0, 1)",
              borderRadius: "0",
            },
          },
        },
      },
      defaultProps: {
        inputProps: {
          style: {
            fontSize: "16px",
            borderRadius: "0",
            // height: '.85rem',
          },
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: { variant: 'squarePurple' },
          style: {
            textTransform: 'none',
            backgroundColor: '#f0eef6',
            padding: "8px 22px",
            color: "#FFFFFF",
            '&:hover': {
              backgroundColor: '#a496c8',
            },
          },
        },
        {
          props: { variant: 'squareBlack' },
          style: {
            textTransform: 'none',
            backgroundColor: '#000000',
            padding: "8px 22px",
            color: "#FFFFFF",
            borderRadius: "0",
            '&:hover': {
              backgroundColor: '#666666',
            }, '&:disabled': {
              backgroundColor: '#666666',
              color: '#FFFFFF'
            },
          },
        },
        {
          props: { variant: 'squareBlackFixed' },
          style: {
            textTransform: 'none',
            backgroundColor: '#000000',
            padding: "8px 22px",
            borderRadius: "4px",
            color: "#FFFFFF",
            '&:hover': {
              backgroundColor: '#666666',
            },
          },
        },
        {
          props: { variant: 'squareOutline' },
          style: {
            textTransform: 'none',
            backgroundColor: '#ffffff',
            padding: "8px 22px",
            borderColor: '#000000',
            borderWidth: '1px',
            borderStyle: 'solid',
            color: "#000000",
            borderRadius: "0",
            '&:hover': {
              backgroundColor: '#cccccc',
            },
          },
        },
        {
          props: { variant: 'squarePurpleOutline' },
          style: {
            textTransform: 'none',
            backgroundColor: '#e6e4ec',
            padding: "8px 22px",
            borderColor: '#000000',
            borderWidth: '1px',
            borderStyle: 'none',
            color: "#6750A4",
            borderRadius: "0",
            '&:hover': {
              backgroundColor: '#d2cbe4',
            },
          },
        },
        {
          props: { variant: 'roundedBlack' },
          style: {
            textTransform: 'none',
            backgroundColor: '#000000',
            padding: "8px 22px",
            borderRadius: "100px",
            color: "#FFFFFF",
            '&:hover': {
              backgroundColor: '#666666',
            }, '&:disabled': {
              backgroundColor: '#666666',
              color: '#FFFFFF'
            },

          },
        },
        {
          props: { variant: 'roundedPurple' },
          style: {
            textTransform: 'none',
            backgroundColor: '#6750A4',
            padding: "8px 22px",
            borderRadius: "100px",
            color: "#FFFFFF",
            '&:hover': {
              backgroundColor: '#a496c8',
            },
          },
        },
        {
          props: { variant: 'roundedPurpleLight' },
          style: {
            textTransform: 'none',
            backgroundColor: '#f0eef6',
            padding: "8px 22px",
            borderRadius: "100px",
            '&:hover': {
              backgroundColor: '#d0c9e1',
            },
          },
        },
        {
          props: { variant: 'roundedGrey' },
          style: {
            textTransform: 'none',
            backgroundColor: '#EFEFEF',
            padding: "8px 22px",
            borderRadius: "100px",
            '&:hover': {
              backgroundColor: '#c6c6c6',
            },
          },
        },
        {
          props: { variant: 'roundedOutline' },
          style: {
            textTransform: 'none',
            borderColor: '#000000',
            borderWidth: '1px',
            borderStyle: 'solid',
            color: "#6750A4",
            padding: "8px 22px",
            borderRadius: "100px",
            '&:hover': {
              backgroundColor: '#e1dced',
            },
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#FFFFFF'
    },
    info: {
      main: '#6750A4'
    },
    success: {
      main: '#f0eef6'
    }
  }
})

const SignInRoute: React.FunctionComponent = () => (

  <Router>

    <Header />
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path={"/signout"} element={<SignOut />} />
      <Route path="/signupbusiness" element={<SignUp />} />
      <Route path={"/signupemployee" + "/:id"} element={<SignUpEmployee />} />

      <Route path="/verifyemployee" element={<VerifyEmployeeCode />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />

      <Route path={"/business/view" + "/:id"} element={<ViewBusiness />} />
      <Route path={"/employee/view" + "/:id"} element={<ViewEmployee />} />
      <Route path={"/employee/tip" + "/:id"} element={<TipEmployee />} />
      <Route path={"/business/tip" + "/:id"} element={<TipBusiness />} />
      <Route path={"/tipcheckout"} element={<TipCheckout />} />
      <Route path={"/searchBusinessQR/"} element={<SearchBusinessQR />} />
      <Route path={"/searchBusiness/"} element={<SearchBusiness />} />
      <Route path={"/searchBusinessEmployee"} element={<SearchBusinessEmployee />} />
      <Route path={"/thankyou/"} element={<Thankyou />} />
      <Route path={"/invite"} element={<InviteFriends />} />
      <Route path={"/create"} element={<Landing2 />} />
      <Route path="/redirect" element={<Redirect />} />
      <Route path={"/faq"} element={<FAQ />} />
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
    <Footer />
  </Router>
)

const MainRouteAdmin: React.FunctionComponent = () => (
  <Router>
    <Header />
    <Routes>
      <Route path={"/setupBusiness"} element={<SetupBusiness />} />
      <Route path="/" element={<SetupBusiness />} />
      <Route path="*" element={<SetupBusiness />} />
      </Routes>
    <Footer />
  </Router>
)
const MainRouteBusiness: React.FunctionComponent = () => (

  <Router>
    <Header />
    <Routes>
      <Route path={"/signin"} element={<SignIn />} />
      <Route path={"/signout"} element={<SignOut />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path={"/business/edit/"} element={<EditBusiness />} />
      <Route path={"/business/finalize/"} element={<FinalizeBusiness />} />
      <Route path={"/business/view" + "/:id"} element={<ViewBusiness />} />
      <Route path={"/business/tip" + "/:id"} element={<TipBusiness />} />
      <Route path={"/business/viewprofile"} element={<ViewBusiness />} />
      <Route path={"/business/qr/"} element={<QRBusiness />} />
      <Route path={"/business/stripe/"} element={<StripeBusiness />} />
      <Route path={"/business/dashboard/"} element={<DashboardBusiness />} />
      <Route path={"/business/invite/"} element={<InviteBusiness />} />
      <Route path={"/business/inviteclients/"} element={<InviteBusinessClients />} />
      <Route path={"/business/finish/"} element={<FinishBusiness />} />
      <Route path={"/employee/tip" + "/:id"} element={<TipEmployee />} />
      <Route path={"/employee/view" + "/:id"} element={<ViewEmployee />} />
      <Route path={"/searchBusinessQR/"} element={<SearchBusinessQR />} />
      <Route path={"/searchBusiness/"} element={<SearchBusiness />} />
      <Route path={"/tipcheckout/"} element={<TipCheckout />} />
      <Route path={"/thankyou"} element={<Thankyou />} />
      <Route path={"/faq"} element={<FAQ />} />
      <Route path={"/invite"} element={<InviteFriends />} />
      <Route path="/redirect" element={<DashboardBusiness />} />
      <Route path={"/business/landing"} element={<Landing />} />
      <Route path="/" element={<DashboardBusiness />} />
      <Route path="*" element={<DashboardBusiness />} />
    </Routes>
    <Footer />
  </Router>
)



const MainRouteEmployee: React.FunctionComponent = () => (

  <Router>
    <Header />
    <Routes>
      <Route path={"/signout"} element={<SignOut />} />
      <Route path={"/signin"} element={<SignIn />} />
      <Route path={"/employee/finalize/"} element={<FinalizeEmployee />} />

      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path={"/business/view" + "/:id"} element={<ViewBusiness />} />
      <Route path={"/business/tip" + "/:id"} element={<TipBusiness />} />
      <Route path={"/employee/edit/"} element={<EditEmployee />} />
      <Route path={"/employee/tip" + "/:id"} element={<TipEmployee />} />
      <Route path={"/employee/view" + "/:id"} element={<ViewEmployee />} />
      <Route path={"/employee/viewprofile"} element={<ViewEmployee />} />
      <Route path={"/employee/stripe/"} element={<StripeEmployee />} />
      <Route path={"/employee/dashboard/"} element={<DashboardEmployee />} />
      <Route path={"/employee/qr/"} element={<QREmployee />} />
      <Route path={"/employee/invite/"} element={<InviteEmployee />} />
      <Route path={"/employee/finish/"} element={<FinishEmployee />} />
      <Route path={"/searchBusinessQR/"} element={<SearchBusinessQR />} />
      <Route path={"/searchBusiness/"} element={<SearchBusiness />} />
      <Route path={"/tipcheckout/"} element={<TipCheckout />} />
      <Route path={"/thankyou"} element={<Thankyou />} />
      <Route path={"/faq"} element={<FAQ />} />
      <Route path={"/invite"} element={<InviteFriends />} />
      <Route path={"/employee/landing"} element={<Landing />} />
      <Route path="/redirect" element={<DashboardEmployee />} />
      <Route path="/" element={<DashboardEmployee />} />
      <Route path="*" element={<DashboardEmployee />} />
    </Routes>
    <Footer />
  </Router>
)

function App() {

  const businessProps = { // make sure all required component's inputs/Props keys&types match
    business: true
  }

  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <ConfigureAmplifyClientSide />
        <AuthProvider>
          <AuthIsSignedIn>
            <AuthIsBusiness>
              <MainRouteBusiness />
            </AuthIsBusiness>
            <AuthIsEmployee>
              <MainRouteEmployee />
            </AuthIsEmployee>
            
            <AuthIsAdmin>
              <MainRouteAdmin />
            </AuthIsAdmin>
          </AuthIsSignedIn>
          <AuthIsNotSignedIn>
            <SignInRoute />
          </AuthIsNotSignedIn>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
