import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidCode, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'
import { sendEmailBusinessSignup } from '../../libs/email'



const VerifyCode: React.FunctionComponent<{}> = () => {
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { code, setCode, codeIsValid } = useValidCode('')
  const [error, setError] = useState('')
  const [queryParameters] = useSearchParams()

  const isValid = !codeIsValid || code.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const autoVerify = async() => {
    try{
      console.log("Verifying");
      if(queryParameters.get("userid")===null)
        return;
      await authContext.verifyCode(queryParameters.get("userid"), queryParameters.get("usercode"))
      console.log("Sending Email "+queryParameters.get("userid"));
      sendEmailBusinessSignup(queryParameters.get("userid")!);
      navigate('/signin?verified=true')
      setError('')
    }catch (err) {
      setError('Invalid Code')
    }

  }

  useEffect(() => {
  if(queryParameters.get("userid")){
    autoVerify();
  }
  },[]);
  
  const sendClicked = async () => {
    try {
      const email = localStorage.getItem('email') || "";
      //console.log(email)
      await authContext.verifyCode(email, code)
    
      sendEmailBusinessSignup(email);
      setError('')  
      navigate('/signin')
      
    } catch (err) {
      setError('Invalid Code')
    }
  }

  const passwordResetClicked = async () => {

    try {

      await authContext.resendCode(localStorage.getItem('email'))
      setError('')
    } catch (err) {
      setError('Enter Email')
    }
    // navigate('/resetpassword')
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box mt={2} mb={2}>
            <Typography variant="h3">Verify Account</Typography>
          </Box>

          {/* Sign In Form */}

          <Box mt={1} mb={1} width="80%">
          <Grid container direction="row" justifyContent={"center"} alignItems="center">
              <Box onClick={passwordResetClicked} mt={2}>
              <Typography gutterBottom align='center' sx={{ '&:hover': { cursor: 'pointer' } }} variant="h4">
                  Thank you for registering.
                  
                </Typography>
                <Typography gutterBottom align='center' sx={{ '&:hover': { cursor: 'pointer' } }} variant="body1">
              
                Please check your email for verification link.
                </Typography>

                
                <Box mt={2}>
                  <Typography color="error" variant="body1">
                    {error}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid container direction="row"  justifyContent={"center"} alignItems="center">
              <Box onClick={passwordResetClicked} mt={2}>
                <Typography sx={{ '&:hover': { cursor: 'pointer' } }} variant="body1">
                  Resend Link
                </Typography>
                <Box mt={2}>
                  <Typography color="error" variant="body1">
                    {error}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Box>

          {/* Buttons */}

          <Box mt={2}>
            <Grid container direction="row" >
              <Box mt={1} mb={1}>
                <Button color="primary" variant="squareBlack" onClick={() => navigate('/')}>
                  Home
                </Button>
              </Box>
             
            </Grid>
          </Box>
          
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VerifyCode
