import { Button, Fab, Typography } from "@mui/material";
import { type FC } from "react";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';

const ShareController: FC<Props> = ({
  children,
  shareData,
  fullWidth,
  fixed,
  onInteraction,
  onSuccess,
  onError,
  onNonNativeShare,
  disabled,
}) => {
  const handleOnClick = async () => {
    onInteraction?.();
    if (navigator?.share) {
      try {
        await navigator.share(shareData);
        onSuccess?.();
      } catch (err) {
        onError?.(err);
      }
    } else {
      onNonNativeShare?.();
    }
  };

  return (
    <>
      {
        fixed ?
          <Button fullWidth type="button" disabled={disabled} onClick={handleOnClick} variant="roundedBlack">
             <IosShareOutlinedIcon />
            <Typography variant="subtitle2" ml={1}>Share Tipz</Typography>
            </Button>
          :
          fullWidth ?
            <Button fullWidth type="button" disabled={disabled} onClick={handleOnClick} variant="roundedBlack">
             <IosShareOutlinedIcon />
            <Typography variant="subtitle2" ml={1}>Share Tipz</Typography>
            </Button>

            :

            <Button type="button" disabled={disabled} sx={{ flexbox: 1, display: "flex" }} onClick={handleOnClick} variant="roundedBlack">
             <IosShareOutlinedIcon />
            <Typography variant="subtitle2" ml={1}>Share Tipz</Typography>
            </Button>
      }
    </>
  );
};

interface Props {
  children: React.ReactNode;
  shareData: ShareData;
  fullWidth?: boolean;
  fixed?: boolean;
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  onNonNativeShare?: () => void;
  onInteraction?: () => void;
  disabled?: boolean;
}

export default ShareController;
