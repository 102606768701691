import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useRoutes, useSearchParams, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import LogoutIcon from '@mui/icons-material/Logout';
import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'
import { Path } from "../../paths";

import { AuthContext, AuthStatus } from '../../context/authContext'
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { AppBar, BottomNavigation, BottomNavigationAction, List, ListItem, Toolbar } from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode';
import PaidIcon from '@mui/icons-material/Paid';
import EmailIcon from '@mui/icons-material/Email';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Hub } from 'aws-amplify/utils'
const Footer: React.FunctionComponent<{}> = () => {
 // //console.log("HEADER")
  const navigate = useNavigate()

  const auth = useContext(AuthContext)
  const [error, setError] = useState('')

  const authContext = useContext(AuthContext)

const [setupComplete, setSetupComplete] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
     setSetupComplete(false)

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {
      setSetupComplete(false)
      
    }else {
      setSetupComplete(true);
    }
  }, [authContext]);



  const signOutClicked = async() =>{
    await auth.signOut()
    
  }

  function editClicked() {
    
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessViewProfile}/`);
    else
      navigate(`${Path.employeeViewProfile}/`);

  }
  function paymentClicked() {
   if(!setupComplete)
    return;
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessStripe}/`);
    else
      navigate(`${Path.employeeStripe}/`);

  }
  function inviteClicked() {
   
    if(!setupComplete)
      return;
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessInvite}/`);
    else
      navigate(`${Path.employeeInvite}/`);

  }
  function goHome() {
   
    if(!setupComplete)
      return;
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessDashboard}`)
    else if (authContext.sessionInfo?.usertype === "employee")
      navigate(`${Path.employeeDashboard}`)
    else
      navigate(`${Path.home}`)
  }
  function qrClicked() {
    
    
   if(!setupComplete)
    return;
  if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessQR}/`);
    else
      navigate(`${Path.employeeQR}/`);
  }

  const [value, setValue] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      {
        authContext.authStatus === AuthStatus.SignedIn ?
          <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>

              <Box sx={{ flexGrow: 1 }} alignItems="center">
                <Grid container direction="row" justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <IconButton onClick={goHome} >
                    {window.location.href.toLowerCase().includes("qr")||window.location.href.toLowerCase().includes("edit")
                    ||window.location.href.toLowerCase().includes("finish")||window.location.href.toLowerCase().includes("invite")
                    ||window.location.href.toLowerCase().includes("profile")||window.location.href.toLowerCase().includes("stripe")
                    ?
                    <HomeOutlinedIcon sx={{transform: "scale(1.2)"}} />:
                    
                    <HomeOutlinedIcon sx={{color:"#6750A4",transform: "scale(1.2)"}} />}
                    </IconButton>
                    <Typography align="center" variant="body2" onClick={goHome}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Home</Typography>

                  </Grid>
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <IconButton onClick={qrClicked}>
                    {window.location.href.toLowerCase().includes("qr")
                    ?
                      <QrCodeIcon sx={{color:"#6750A4"}} />:
                      <QrCodeIcon  />}
                    </IconButton>
                    <Typography align="center" variant="body2" onClick={qrClicked}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>My QR</Typography>

                  </Grid>
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <IconButton onClick={inviteClicked}>
                    {window.location.href.toLowerCase().includes("invite")
                    ?
                    <PersonAddAltOutlinedIcon sx={{color:"#6750A4"}}/>:
                    
                    <PersonAddAltOutlinedIcon />}
                    </IconButton>
                    <Typography align="center" variant="body2" onClick={inviteClicked}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Invite</Typography>

                  </Grid>
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <IconButton onClick={editClicked}>
                    {window.location.href.toLowerCase().includes("profile")||window.location.href.toLowerCase().includes("edit")
                    ?<AccountCircleOutlinedIcon sx={{color:"#6750A4"}} />:
                    <AccountCircleOutlinedIcon />}
                    </IconButton>
                    <Typography align="center" variant="body2" onClick={editClicked}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Profile</Typography>

                  </Grid>
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <IconButton onClick={paymentClicked}>
                    {window.location.href.toLowerCase().includes("stripe")
                    ?<AddCardOutlinedIcon sx={{color:"#6750A4"}} />:
                    <AddCardOutlinedIcon />}
                    </IconButton>
                    <Typography align="center" variant="body2" onClick={paymentClicked}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Payment</Typography>

                  </Grid>
                  <Grid item direction="column" sx={{ flexGrow: 1, display: "flex" }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
                    <Box sx={{ flexGrow: 1 }} justifyItems="center" alignItems="center" justifyContent="center" alignContent="center"><IconButton onClick={signOutClicked}>
                      <LogoutIcon />
                    </IconButton>
                    </Box>
                    <Typography align="center" variant="body2" onClick={signOutClicked}
                      sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Logout</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Toolbar>
          </AppBar>

          :
          <></>
      }
    </Box>
  )
}

export default Footer
