import React, { useState, useContext, useEffect } from 'react'
import http from "../../http-common";
import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Path } from "../../paths";
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import {Employee} from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Email } from '../../components/authComponents'

const FinishEmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const { email, setEmail, emailIsValid } = useValidEmail('')
  const params = useParams<{ id: string }>();
  const authContext = useContext(AuthContext)
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");

  
  useEffect(()=>{
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);
  
    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {
  
      navigate(`${Path.employeeEdit}/`);
    }
  });
  const form = useForm<Employee>({
  
  })

  type Invite = {
    name:string,
    email:string
  }

  const { register, handleSubmit, watch } = form;
  const [error, setError] = useState('')




  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () =>{
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

    
  const inviteClients=()=>{
    
    navigate(`${Path.employeeInvite}/`);
  }
    
  const viewProfile=()=>{
    
    navigate(`${Path.employeeViewProfile}/`);
  }
     
  const finalize = (
    <>
  
<Box alignItems={"center"} justifyItems="center" justifyContent="center" alignContent="center">
    <CheckCircleIcon sx={{  width: "100px", height: "100px" }} />
    </Box>
      {/* Title */}
      <Box mt={1} mb={1}>
        <Grid container direction="column" alignItems="center">
          <Typography align="center" variant="h3" gutterBottom>Setup Complete</Typography>
          <Typography align="center" variant="subtitle1" gutterBottom>Thank you for choosing Tipz!</Typography>
        </Grid>
      </Box>
      <Box >
        <Grid container direction="column" alignItems="center">
          <Button  onClick={viewProfile} variant="roundedOutline">
            View Profile
          </Button>
          </Grid>
      </Box>

      <Box mt={1}>
        <Grid container direction="column" alignItems="center">
          <Typography align="center" variant="subtitle1" gutterBottom>Tell your clients about us</Typography>
          <Typography align="center" variant="subtitle1" gutterBottom>Let your clients know they can now show their gratitude in a few clicks!</Typography>
        </Grid>
      </Box>

      <Box mt={1} width="100%">
        <Grid container direction="row" justifyContent="center" alignItems="center"   >
            <Button  onClick={inviteClients} variant="roundedPurple">
              Invite Clients
            </Button>
            

        </Grid>
      </Box>
      <Box width="100%" mt={1} textAlign="center">

        <img height={'auto'} width={'50%'} src="https://dpcy146y2wbkr.cloudfront.net/handshake.png" />
      </Box>
    </>
  )


  return (
    <Grid  container direction="row" justifyContent="center" alignItems="center">
    <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">


          {finalize}
        </Grid>
    </Grid>
  </Grid>
  )
}

export default FinishEmployee
