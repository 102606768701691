import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat, NumberFormatBase  } from 'react-number-format'
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import {
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { Tip } from '@prisma/client';
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { Controller, Control, useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase, Checkbox, Drawer, Modal, Stack, Tooltip } from '@mui/material';
import { paymentIntent } from '../../utils/stripe/payment-intent-utils';
import { v4 as uuidv4 } from 'uuid';

const TipEmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();
  const { email, setEmail, emailIsValid } = useValidEmail('')

  const form = useForm<Tip>({

  })
  const { register, handleSubmit, control, watch, formState: { errors } } = form;
  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetBusinessQuery(params.id || "");

  const [showTooltip, setShowTooltip] = useState(false);
  

  const onSubmit = (data: any) => console.log(data)

  const [error, setError] = useState('')
  const [fee, setFee] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [feeChecked, setFeeChecked] = useState(false)

  
  const isValid =
  !emailIsValid ||
  email.length === 0||total===0

const isEmpty =
  email.length === 0
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const amountVal = watch("amount", 0);


  React.useEffect(() => {


    const subscription = watch((value, { name, type }) => {


      let tot = Number(value.amount?.toString().replace(/[^0-9\.-]+/g, ""))
      setTotal(tot)

      const tot2 = fee + tot
      //console.log(tot2)
      setTotalAmount(tot2)


    }



    )
    return () => subscription.unsubscribe()
  }, [watch])


  if (isLoading) {
    return <span>Loading</span>;
  }

  const feeCheckChanged = (event: any) => {
    const feeTot: number = event.target.checked ? total*0.2 : 0
    const tot = feeTot + total
    setFeeChecked(event.target.checked)
    setFee(feeTot)

    //console.log(tot)
    setTotalAmount(tot)
  }
  
  const handleTipBusiness = async (tip: Tip) => {
    //console.log(data)
    try {
      if (!data || data.stripeid === "")
        return;
      let tipString = tip.amount.toString()
      let number = Number(tipString.replace(/[^0-9\.-]+/g, ""));

      feeChecked ? number = number + fee : number = number;
      console.log("Tip " + number);
      const client_secret: unknown = await paymentIntent(
        'https://tipzserver.com/backend/secret', number, data?.stripeid, uuidv4()
        //'https://tipzserver.com/backend/secret', number, data?.stripeid
      )
      tip.recipientName = data.businessName;
      tip.recipientEmail = data.email
      tip.fee = fee
      tip.tipId2 = data.id
      tip.tipId = null

      tip.logourl = data.logoUrl
      navigate('/tipcheckout/', {
        state: {
          client_secret,
          totalAmount,
          tip
        }
      })
    } catch (error) {
      alert('An error has occurred; try again later!')
    }
  }
  

  const confirmTip = async () => {

    const response = await fetch("https://tipzserver.com/backend/api/employees/createstripe", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/createstripe", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const dataRes = await response.json();
    //console.log(dataRes.url)
    let splitString = dataRes.url.split("/")
    //console.log(splitString[splitString.length - 2])
    //handleEditEmployee(splitString[splitString.length - 2])
    window.location.href = dataRes.url
  };

  function currencyFormatter(value:string) {
    if (!Number(value)) return '';
    
    
    const amount = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(value) / 100);
    
    return `${amount}`;
    }

    

  
  const finalize = (
    <>



      <Box mt={2} width="100%">

        <Paper elevation={0} variant="outlined"
          sx={{
            p: 1,
            flexGrow: 1,
            borderRadius: "24px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container direction="row">
            <Grid item>
              <ButtonBase sx={{ width: 128, height: 128 }}>
                <Avatar sx={{ height: "90px", width: "90px" }} src={data?.logoUrl} />

              </ButtonBase>
            </Grid>
            <Grid item xs={6} sm container>

              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography align="left" gutterBottom variant="h5" component="div">
                      {data?.businessName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="left" variant="subtitle1" gutterBottom>
                      {data?.address}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="left" variant="subtitle1" gutterBottom>

                    <Link style={{color:"#000000"}} target="_blank" to={data ? data?.website : ""} > {data?.website}</Link>
                    </Typography>
                  </Grid> <Grid item>
                    <Typography align="left" variant="subtitle1" gutterBottom>

                      {data?.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {data && data.bio ?
          <Box mt={2} width="100%">
            <Paper elevation={0} variant="outlined"
              sx={{
                p: 1,
                borderRadius: "24px",
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    {data?.bio}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>



          </Box>
        :
        <></>
          }
      </Box>

      <Box justifyItems="center" width="100%" >
        <form >
          <Box mt={2} width="100%" >
            <Box width="100%" mt={1} mb={1}>
              <Controller
                {...register('amount')}
                name="amount"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <NumberFormatBase 
                  inputProps={{ inputMode: 'decimal' }}
                    id="amount"
                    format={currencyFormatter}

                    customInput={TextField}
                    fullWidth
                    label={'Select Amount'}
                    //thousandSeparator=","
                    //decimalSeparator="."
                    prefix="$ "
                    //fixedDecimalScale ={true} 
                    //decimalScale={2}
                    getInputRef={ref}
                    {...rest}
                  />
                )}
              />
              {!!errors && <span>{errors.amount?.message as string}</span>}


            </Box>
            <Box width="100%" mt={1} mb={1}>
              <TextField
                fullWidth
                type="text"
                {...register("message")}
                variant="outlined"
                label={`Message for ${data?.businessName}`}
              />
            </Box>

            <Box width="100%" mt={1} mb={1}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                {...register("name")}
                label={'Name'}
              />
            </Box>
            <Box width="100%" mt={1} mb={1}>
            <TextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  {...register("email")}
                  onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setEmail(evt.target.value)
                  }}
                />
            </Box>

            <Box mt={2}>
              <Typography color="error" variant="body1">
                {error}
              </Typography>
            </Box>

            {/* Buttons */}
            <Box mt={2} width="100%" p={4}>
              <Grid container direction="row" spacing={2} >
                <Box width="100%">
                  <Button disabled={isValid||isEmpty} fullWidth onClick={handleOpen} variant="roundedBlack">
                    Next
                  </Button>
                </Box>

              </Grid>
            </Box>

            <Drawer open={open}
              onClose={handleClose} anchor="bottom">

              <Box p={4} sx={{ backgroundColor: "#6750A4" }}>
                <Typography id="modal-modal-title" color="white" variant="h3" align="center" component="h2">
                  Confirm your tip
                </Typography>
                {/*
                <Stack direction="row"
                  justifyContent="center"
                  alignItems="center" spacing={1}>
                  <Checkbox sx={{ color: "white" }} onChange={feeCheckChanged} />
                  
                  <Typography color="white" id="modal-modal-description" variant="h5" sx={{ mt: 1 }}>
                    Cover {data?.businessName}'s processing fee?    +${(Math.round(total * 20) / 100).toFixed(2)}
                  </Typography >
                  <Tooltip open={showTooltip} onOpen={()=> setShowTooltip(true)} onClose={()=>setShowTooltip(false)} title="This fee covers costs including credit card processing." placement="top-end">
                  <HelpOutlineOutlinedIcon onClick={() => setShowTooltip(!showTooltip)} sx={{color:"#ffffff"}}/>
                  </Tooltip>
                </Stack>
                */}
                <Box width="100%" mt={1} mb={1}>
                  <Button fullWidth color="secondary" sx={{ textTransform: "none", borderRadius: "100px", padding: "8px 22px" }} type="submit" onClick={handleSubmit(handleTipBusiness)} variant="contained">
                    <Typography variant="subtitle2">Pay ${totalAmount.toFixed(2)}</Typography>
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </Box>
        </form>
      </Box>
    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4}  style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h3" >
            You're tipping
          </Typography>
          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}


export default TipEmployee
